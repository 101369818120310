
export const FIRST_TIME_DEP = 'ID15';
export const FIRST_DEP = 'ID15';
export const SECOND_DEP = 'ID16';
export const THIRD_DEP = 'ID17';

export const ALL_WELCOME_BONUSES = 'ID195';
export const CLAIMED_WELCOME_BONUSES_GROUP = 'ID195';
export const USER_HAVE_ONE_DEPOSIT_GROUP = 'ID209';
export const USER_RECEIVED_PHONE_BONUS = 'ID210';
